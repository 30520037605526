import React from 'react';
import { Hls as HlsComponent } from '@voomly/ui/player-deps';
import type {
  IGeneralVideoControlInterface,
  IGeneralVideoControlProps,
} from '../videoControlInterface';
import type Hls from 'hls.js/dist/hls.light';
import removeOperaPiP from '../removeOperaPiP';
import { VideoStateForLoaderListener } from '../VideoStateForLoaderListener';
import { mergeRefs } from '@voomly/utils';
import { IInternalVideoProps } from '../videoControlInterface';

interface IState {
  load: boolean;
  isShowingScreenshot: boolean;
  error?: Error;
  quality: number;
}

class HlsVideo
  extends React.PureComponent<
    IGeneralVideoControlProps & IInternalVideoProps,
    IState
  >
  implements IGeneralVideoControlInterface
{
  private video: HTMLVideoElement | undefined | null;
  private videoStateListener = new VideoStateForLoaderListener();

  public state: IState = {
    load: false,
    isShowingScreenshot: false,
    quality: -1,
  };

  private timer?: number;
  private previousLoadedPercents = 0;
  private reportingVolumeChanges = true;

  public componentDidMount() {
    this.props.onMount(this);

    this.videoStateListener.listen(this.props.onVideoBufferingToggle);

    // @ts-ignore
    window.hlsVideo = this;
    removeOperaPiP();
  }

  getDuration() {
    if (this.video) return this.video.duration;

    return 0;
  }

  public getIsMuted = () => {
    return this.video ? this.video.muted : false;
  };

  public getIsPlaying = () => {
    return this.video ? !this.video.paused : false;
  };

  public getCurrentTime = () => {
    return this.video?.currentTime ?? 0;
  };

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public seekAndBuffer = (time: number, cb: () => void) => {
    if (!this.video) {
      return;
    }

    this.video.addEventListener('canplaythrough', cb, { once: true });

    this.seek(time);
  };

  public play = async () => {
    if (this.video) {
      this.setState({ load: true });
      await this.video.play();
    }
  };

  getVolume = () => {
    return this.video ? this.video.volume : 1;
  };

  public pause = () => {
    if (this.video) {
      this.video.pause();
    }
  };

  public seek = (time: number) => {
    if (this.video) {
      this.setState({ load: true });
      this.video.currentTime = time;
    }
  };

  public setVolume = (value: number) => {
    if (this.video) {
      this.video.volume = value;
    }
  };

  public setMuted = (value: boolean) => {
    if (this.video) {
      this.video.muted = value;
    }
  };

  public getMuted = () => {
    if (this.video) return this.video.muted;
    return false;
  };

  public pauseReportingVolumeChanges = () => {
    this.reportingVolumeChanges = false;
  };

  public resumeReportingVolumeChanges = () => {
    this.reportingVolumeChanges = true;
  };

  public setSpeed = (value: number) => {
    if (this.video) {
      this.video.playbackRate = value;
    }
  };

  public setQuality(quality: number, hlsUrl: string): void {
    this.setState({
      quality,
    });

    this.props.onQualityChange(quality);
  }

  public onError = (error: string | Hls.errorData) => {
    const { onVideoSourceError } = this.props;
    onVideoSourceError(typeof error === 'string' ? error : error.details);
  };

  private handleOnReady = () => {
    this.props.onReady();

    this.timer = window.setInterval(this.collectVideoData, 500);
  };

  private collectVideoData = () => {
    if (!this.video) return;

    const ranges = this.video.buffered;
    const currentTime = this.video.currentTime;
    let i = ranges.length;
    const candidates: number[] = [];
    while (i--) {
      const start = ranges.start(i);
      const end = ranges.end(i);
      if (start <= currentTime && currentTime <= end) {
        candidates.push(end);
      }
    }

    const winner = Math.max(...candidates);
    const videoDuration = this.video.duration;
    const loadedPercents = videoDuration ? winner / videoDuration : 0;

    if (loadedPercents !== this.previousLoadedPercents) {
      this.props.onPercentsLoaded(loadedPercents);
      this.previousLoadedPercents = loadedPercents;
    }
  };

  registerRef =
    (registerVideoRef: (video: HTMLVideoElement | null | undefined) => void) =>
    (el: HTMLVideoElement | undefined | null) => {
      if (this.video) {
        this.video.removeEventListener('play', this.handleVideoPlayRequested);
        this.video.removeEventListener('playing', this.handleVideoStartPlaying);
        this.video.removeEventListener('pause', this.handleVideoStoppedPlaying);
        this.video.removeEventListener(
          'volumechange',
          this.handleVideoVolumeChange
        );
        this.video.removeEventListener(
          'ratechange',
          this.handleVideoRateChange
        );
        this.video.removeEventListener('timeupdate', this.handleTimeUpdate);
        this.video.removeEventListener('seeking', this.handleSeeking);
        this.video.removeEventListener('seeked', this.handleSeeked);
        this.video.removeEventListener('ended', this.handleEnded);
      }

      registerVideoRef(el);
      this.video = el;

      if (this.video) {
        this.video.addEventListener('play', this.handleVideoPlayRequested);
        this.video.addEventListener('playing', this.handleVideoStartPlaying);
        this.video.addEventListener('pause', this.handleVideoStoppedPlaying);
        this.video.addEventListener(
          'volumechange',
          this.handleVideoVolumeChange
        );
        this.video.addEventListener('ratechange', this.handleVideoRateChange);
        this.video.addEventListener('timeupdate', this.handleTimeUpdate);
        this.video.addEventListener('seeking', this.handleSeeking);
        this.video.addEventListener('seeked', this.handleSeeked);
        this.video.addEventListener('ended', this.handleEnded);
      }
    };

  private handleEnded = () => {
    if (this.video) {
      this.props.onEnded();
    }
  };

  private handleVideoPlayRequested = () => {
    if (this.video) {
      this.props.onPlayRequested();
    }
  };
  private handleVideoStartPlaying = () => {
    if (this.video) {
      this.props.onPlay();
    }
  };

  private handleVideoStoppedPlaying = () => {
    if (this.video) {
      this.props.onPause();
    }
  };

  private handleVideoVolumeChange = () => {
    if (this.video && this.reportingVolumeChanges) {
      this.props.onVolumeChange(this.video.volume, this.video.muted);
    }
  };

  private handleVideoRateChange = () => {
    if (this.video) {
      this.props.onRateChange(this.video.playbackRate);
    }
  };

  private handleTimeUpdate = () => {
    this.videoStateListener.notify(() => this.video?.readyState || 0);
    if (this.video) {
      this.props.onTimeUpdate(this.video.currentTime);
    }
  };

  private handleSeeking = () => {
    if (this.video) {
      this.props.onSeeking(this.video.currentTime, 'seeking');
    }
  };

  private handleSeeked = () => {
    if (this.video) {
      this.props.onSeeking(this.video.currentTime, 'seeked');
    }
  };

  render() {
    const { url } = this.props;
    const { load, quality } = this.state;

    return (
      <HlsComponent
        key={url}
        url={url}
        load={load}
        quality={quality}
        onError={this.onError}
        onReady={this.handleOnReady}
      >
        {({ registerVideoRef }) => {
          return (
            <video
              // safari requires `playsInline` attr for autoplay and manual calls of `play` method.
              // https://webkit.org/blog/6784/new-video-policies-for-ios/
              playsInline
              ref={mergeRefs(
                this.registerRef(registerVideoRef),
                this.props.videoRef
              )}
            />
          );
        }}
      </HlsComponent>
    );
  }
}

export default HlsVideo;
