import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import buttonStyles from '../Button/button.module.sass';
import { ReactComponent as LogoIcon } from '../../../svg/logo.svg';
import styles from './logo.module.sass';
import { getStyles } from '../skins/getStyles';
import { A } from '../../A/A';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

const Logo = ({ player }: IDefaultPropTypes) => {
  const {
    appearance: { customLogoUrl, customUrl, useCustomLogo, logoEnabled },
    captions,
  } = player;

  const isLogoVisible = useSelector(
    (state: IPlayerRootReducerShape) => state.videoControls.isLogoVisible
  );

  const renderCustomLogo = useCallback(() => {
    if (!customLogoUrl) return null;

    return customUrl ? (
      <A href={customUrl} openInNewTab={true}>
        <img src={customLogoUrl} alt={captions.logoText} />
      </A>
    ) : (
      <img src={customLogoUrl} alt={captions.logoText} />
    );
  }, [customLogoUrl, customUrl, captions.logoText]);

  const skin = usePlayerSkin();

  if (!logoEnabled) {
    return null;
  }

  return (
    <div
      style={getStyles('logo', skin, player)}
      className={cx(
        styles.root,
        buttonStyles.root,
        skin.classes.button__root,
        skin.classes.logo,
        {
          [styles.visible]: isLogoVisible,
        }
      )}
    >
      {useCustomLogo ? (
        renderCustomLogo()
      ) : (
        <A
          title="Powered by Voomly"
          href="https://www.voomly.com"
          openInNewTab={true}
        >
          <LogoIcon />
        </A>
      )}
    </div>
  );
};

export default Logo;
