import React from 'react';

let FunnelsPlayer: React.ComponentType<any>;
if (process.env.NX_FUNNEL_INCLUDED) {
  FunnelsPlayer = require('@voomly/funnel').FunnelsPlayer; // eslint-disable-line @typescript-eslint/no-var-requires
} else {
  FunnelsPlayer = () => {
    return <div></div>;
  };
}

export { FunnelsPlayer };
