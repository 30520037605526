import React, { memo, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { isIOS } from '@voomly/utils';
import SliderHandle from '../../Slider/SliderHandle/SliderHandle';
import PopoverTip from '../PopoverTip/PopoverTip';
import Button from '../Button/Button';
import Slider from '../../Slider/Slider';
import { getStyles } from '../skins/getStyles';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { requestVolumeChange } from '../../../store/videoState/actions';
import styles from './volume.module.sass';
import { IPlayerSkin } from '../skins/types';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import {
  getIsVideoMuted,
  getVolume,
} from '../../../store/videoState/selectors';
import { IPlayerWithVideo } from '../../../types/player';

const SliderContent = ({
  player,
  volume,
  skin,
}: {
  player: IPlayerWithVideo;
  volume: number;
  skin: IPlayerSkin;
}) => {
  const finalPercentage = volume <= 0 ? 0 : volume * 100;

  return (
    <div className={styles.trackWrapper}>
      <div
        className={cx(styles.track, skin.classes.volume__track)}
        style={getStyles('volume__track', skin, player)}
      >
        <div
          className={cx(styles.trackBg, skin.classes.volume__trackBg)}
          style={getStyles('volume__trackBg', skin, player)}
        />
        <div
          className={cx(styles.bar, skin.classes.volume__bar)}
          style={{
            ...getStyles('volume__bar', skin, player),
            height: finalPercentage,
          }}
        />
        {skin.handles && (
          <SliderHandle
            handleStyle={getStyles('volume__trackBg', skin, player)}
            position={finalPercentage}
            vertical={true}
          />
        )}
      </div>
      {skin.tips && <PopoverTip skin={skin} config={player} />}
    </div>
  );
};

const VolumeComponent = ({
  player,
  player: { captions },
}: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const mouseDownOnSliderRef = useRef(false);
  const isMouseOverRef = useRef(false);
  const volume = useSelector(getVolume);
  const muted = useSelector(getIsVideoMuted);
  const skin = usePlayerSkin();
  const VolumeIcon = skin.icons.volume;
  const VolumeMutedIcon = skin.icons.volumeMuted;

  const handleMouseMove = useCallback(
    (position: number) => {
      const topOffset = 12;
      const bottomOffset = 12;
      const barHeight = 122;

      const bottomDeadZone = bottomOffset / barHeight;
      const topDeadZone = topOffset / barHeight;

      if (position <= bottomDeadZone) {
        dispatch(requestVolumeChange({ volume: 0, type: 'absoluteVolume' }));
      } else if (position >= 1 - topDeadZone) {
        dispatch(requestVolumeChange({ volume: 1, type: 'absoluteVolume' }));
      } else {
        dispatch(
          requestVolumeChange({
            volume:
              position * (1 + topDeadZone + bottomDeadZone) - bottomDeadZone,
            type: 'absoluteVolume',
          })
        );
      }
    },
    [dispatch]
  );

  const handleClick = useCallback(() => {
    if (volume <= 0 || muted) {
      dispatch(requestVolumeChange({ mute: false, type: 'mute' }));
    } else {
      dispatch(requestVolumeChange({ mute: true, type: 'mute' }));
    }
  }, [dispatch, volume, muted]);

  const handleMouseLeave = useCallback(() => {
    isMouseOverRef.current = false;
    if (!mouseDownOnSliderRef.current) {
      setOpened(false);
    }
  }, []);

  const handleMouseOver = useCallback(() => {
    isMouseOverRef.current = true;
    setOpened(true);
  }, []);

  const handleDocumentMouseUp = useCallback(() => {
    mouseDownOnSliderRef.current = false;
    if (!isMouseOverRef.current) {
      setOpened(false);
    }
  }, []);

  const handleSliderMouseDown = useCallback(() => {
    mouseDownOnSliderRef.current = true;

    document.addEventListener('mouseup', handleDocumentMouseUp);
  }, [handleDocumentMouseUp]);

  if (!player.controls.showVolumeButton) return null;

  return (
    <div
      className={cx(styles.root, skin.classes.volume__root)}
      onMouseLeave={handleMouseLeave}
    >
      {/*
          https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
          On iOS devices, the audio level is always under the user’s physical control. The volume property is not settable in JavaScript. Reading the volume property always returns 1.
        */}
      {!isIOS && (
        <Slider
          className={cx(
            styles.slider,
            skin.classes.controls__popover,
            skin.classes.volume__popover,
            {
              [styles.opened]: opened,
              [skin.classes.controls__popoverOpened]: opened,
              [styles.withTip]: skin.tips,
            }
          )}
          style={getStyles('controls__popover', skin, player)}
          onMouseMove={handleMouseMove}
          onMouseDown={handleSliderMouseDown}
          vertical
        >
          <SliderContent volume={volume} skin={skin} player={player} />
        </Slider>
      )}
      <Button
        className={skin.classes.volume__button}
        isHovered={opened}
        config={player}
        icon={volume <= 0 || muted ? <VolumeMutedIcon /> : <VolumeIcon />}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        title={captions.volumeText}
      />
    </div>
  );
};

export default memo(VolumeComponent);
