import store from 'store';
import CurrentDevice from 'current-device';
import {
  eBrowser,
  eDevice,
  eOS,
  IAnalyticsUser,
} from '../../../types/analytics';
import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
  isSSR,
} from '@voomly/utils';

const getBrowser = (): eBrowser => {
  let browser = eBrowser.other;

  if (isChrome) {
    browser = eBrowser.googleChrome;
  } else if (isSafari) {
    browser = eBrowser.safari;
  } else if (isEdge) {
    browser = eBrowser.microsoftEdge;
  } else if (isIE) {
    browser = eBrowser.internetExplorer;
  } else if (isFirefox) {
    browser = eBrowser.mozillaFirefox;
  } else if (isOpera) {
    browser = eBrowser.opera;
  } else if (isSSR) {
    browser = eBrowser.ssr;
  }

  return browser;
};

const getDevice = (): eDevice => {
  let device = eDevice.other;

  if (CurrentDevice.tablet() || CurrentDevice.ipad()) {
    device = eDevice.tablet;
  } else if (CurrentDevice.mobile()) {
    device = eDevice.mobile;
  } else if (CurrentDevice.desktop()) {
    device = eDevice.desktop;
  }

  return device;
};

const getOS = (): eOS => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = eOS.other;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = eOS.macos;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = eOS.ios;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = eOS.windows;
  } else if (/Android/.test(userAgent)) {
    os = eOS.android;
  } else if (!os && /Linux/.test(platform)) {
    os = eOS.linux;
  }

  return os;
};

const generateRandomToken = () => {
  return Math.random().toString(36).substr(2) + new Date().getTime();
};

const getUserId = () => {
  const key = 'playerUserID';
  const probe = store.get(key);

  if (probe) return probe;

  const value = generateRandomToken();
  store.set(key, value);

  return value;
};

export const getUserInfo = (): IAnalyticsUser => {
  return {
    visitorId: getUserId(),
    os: getOS(),
    device: getDevice(),
    browser: getBrowser(),
  };
};
