import { IPlayerTemplate } from '../../types/player';
import store from 'store';
import { IPlayerVideo } from '../../types/video';
import { PlayerMode } from '../../components/types/defaultPropTypes';

export class AskToResumeOnVisitWatcher {
  private embedVideoId: string | undefined;
  private lastCurrentTime: number | undefined;
  private enabled = false;
  private duration: number | undefined;

  private subscribe() {
    window.addEventListener('beforeunload', this.destruct);
  }

  public unsubscribe() {
    window.removeEventListener('beforeunload', this.destruct);
  }

  public destruct = () => {
    this.saveTimeIfApplicable();
    this.unsubscribe();
  };

  private saveTimeIfApplicable = () => {
    const threshold = 3;
    if (
      this.embedVideoId &&
      this.lastCurrentTime &&
      this.enabled &&
      this.duration
    ) {
      const key = `voomly-lvws-${this.embedVideoId}`;
      if (
        this.lastCurrentTime > threshold &&
        this.lastCurrentTime < this.duration - threshold
      ) {
        store.set(key, this.lastCurrentTime);
      } else {
        store.remove(key);
      }
    }
  };

  public setEmbedVideoId(embedVideoId: string | undefined) {
    this.embedVideoId = embedVideoId;

    this.subscribe();
  }

  public updateLastTime(currentTime: number) {
    this.lastCurrentTime = currentTime;
  }

  public assignPlayer(
    player: IPlayerTemplate,
    file: IPlayerVideo,
    playerMode: PlayerMode
  ) {
    this.duration = file.metadata.duration;
    this.enabled =
      playerMode === PlayerMode.NORMAL &&
      player.controls.resumeOnFutureVisitsEnabled;
  }
}

export const getLastVisitWatchSeconds = (embedVideoId: string) => {
  const seconds = store.get(`voomly-lvws-${embedVideoId}`, undefined);
  if (seconds) return parseInt(seconds, 10);
  return undefined;
};
