import React from 'react';
import * as ReactDOM from 'react-dom';
import './styles/reset.skipScopify.sass';
import './utils/bootstrapInterceptors';

import {
  PlayerExternalAPI,
  PublicPlayerExternalAPI,
  toPublicApi,
} from '@voomly/player';
import { EmbedApp } from './EmbedApp';

const attachedPlayerPairs: Map<HTMLElement, PublicPlayerExternalAPI> =
  new Map();
const attachedPlayerIdPairs: Map<string, VoomlyPlayerById[]> = new Map();

const getPlayerExternalAPIByContainer = (container: HTMLElement) =>
  attachedPlayerPairs.get(container);

const getAllPlayerExternalAPIsById = (id: string) =>
  attachedPlayerIdPairs.get(id);

const getPlayerExternalAPIById = (id: string) =>
  getAllPlayerExternalAPIsById(id)?.[0].api;

const savePlayerAPIForId = (
  id: string,
  type: 'v' | 'f' = 'v',
  element: HTMLDivElement,
  publicAPI: PublicPlayerExternalAPI
) => {
  const apiDescriptorsById = attachedPlayerIdPairs.get(id);

  // Remove prev API descriptors that's not connected
  const items = apiDescriptorsById?.filter((item) => {
    const isConnected = item.element.isConnected;
    if (!isConnected) {
      item.api.destroy();
    }

    return isConnected;
  });
  if (items) {
    attachedPlayerIdPairs.set(id, items);
  }

  const newAPIDescriptor = {
    id,
    type,
    api: publicAPI,
    element,
  };

  if (!apiDescriptorsById) {
    attachedPlayerIdPairs.set(id, [newAPIDescriptor]);
    return;
  }

  apiDescriptorsById.push(newAPIDescriptor);
};

const attachVoomlyPlayer = (
  element: HTMLDivElement,
  id: string,
  type: 'v' | 'f' = 'v',
  ratio: string | '16:9' | 'auto' = 'auto'
) => {
  const loaders = element.getElementsByClassName('voomly-embed-loader');
  if (loaders.length) {
    for (const loader of loaders) {
      loader.remove();
    }
  }

  const renderedVoomlyPlayerAPI = getPlayerExternalAPIByContainer(element);

  // When SSR, player can be rendered on server,
  // so check if the player was rendered on client
  if (element.children.length > 0 && renderedVoomlyPlayerAPI) {
    return renderedVoomlyPlayerAPI;
  }

  const playerExternalAPI = new PlayerExternalAPI(id, {
    mountNode: element,
    isInIframe: window.location !== window.parent.location,
  });

  ReactDOM.render(
    <>
      <EmbedApp
        id={id}
        type={type}
        ratio={ratio}
        playerExternalAPI={playerExternalAPI}
      />
    </>,
    element
  );

  const publicAPI = toPublicApi(playerExternalAPI);

  // Store public player API to access it later by id
  savePlayerAPIForId(id, type, element, publicAPI);

  // Store public player API to access it later by element
  attachedPlayerPairs.set(element, publicAPI);

  return publicAPI;
};

const attachAllVoomlyPlayers = () => {
  const result = new Map<HTMLDivElement, PublicPlayerExternalAPI>();

  const embedVideos = document.getElementsByClassName('voomly-embed');
  if (embedVideos.length) {
    for (const element of embedVideos) {
      const id = element.getAttribute('data-id');
      const type = element.getAttribute('data-type') || 'v';
      const ratio = element.getAttribute('data-ratio') || 'auto';
      if (type !== 'v' && type !== 'f') {
        console.error(`Wrong data-type!`, type);
        continue;
      }

      if (!id) {
        console.error('Missing id!');
        continue;
      }

      result.set(
        element as HTMLDivElement,
        attachVoomlyPlayer(element as HTMLDivElement, id, type, ratio)
      );
    }
  }

  return result;
};

window.voomlyEmbedPlayerApp = {
  attach: attachVoomlyPlayer,
  attachAll: attachAllVoomlyPlayers,
  getPlayerExternalAPIByContainer,
  getAllPlayerExternalAPIsById,
  getPlayerExternalAPIById,
};
