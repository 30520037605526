import { EventEmitter } from 'eventemitter3';
import {
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
  ITimelineItemEnterDescriptor,
  ITimelineItemPreExitDescriptor,
} from '../../../types/types';
import { VoomlyPlayerEvent } from '../VoomlyPlayerEvent';
import { PlayerExternalAPIConnectedChecker } from '../PlayerExternalAPIConnectedChecker';

export type FromPlayerEventTypes = {
  'timeline:unlock': { password: string };

  'timeline:turnstile:completed':
    | { firstName: string; lastName: string; email: string }
    | { email: string };
  'timeline:turnstile:skipped': void;

  'timeline:button:click': ITimelineItemClickDescriptor;
  'timeline:button:enter': ITimelineItemEnterDescriptor;
  'timeline:button:preExit': ITimelineItemPreExitDescriptor;
  'timeline:button:exit': ITimelineItemDismissDescriptor;

  'timeline:image:click': ITimelineItemClickDescriptor;
  'timeline:image:enter': ITimelineItemEnterDescriptor;
  'timeline:image:preExit': ITimelineItemPreExitDescriptor;
  'timeline:image:exit': ITimelineItemDismissDescriptor;

  'timeline:grid:click': ITimelineItemClickDescriptor;
  'timeline:grid:enter': ITimelineItemEnterDescriptor;
  'timeline:grid:preExit': ITimelineItemPreExitDescriptor;
  'timeline:grid:exit': ITimelineItemDismissDescriptor;

  'timeline:survey:click': ITimelineItemClickDescriptor;
  'timeline:survey:enter': ITimelineItemEnterDescriptor;
  'timeline:survey:preExit': ITimelineItemPreExitDescriptor;
  'timeline:survey:exit': ITimelineItemDismissDescriptor;

  'timeline:annotation:click': ITimelineItemClickDescriptor;
  'timeline:imageAnnotation:click': ITimelineItemClickDescriptor;

  'video:ready': void;
  'video:playStarted': void;
  'video:paused': void;
  'video:seekStarted': { time: number };
  'video:seeked': { time: number };
  'video:timeUpdate': { time: number };
  'video:qualityChanged': { quality: number };
  'video:volumeChanged': { volume: number; muted: boolean };
  'video:speedChanged': { speed: number };
  'video:ended': void;

  'video:qualityOptions': {
    options: {
      value: number;
      label: string;
      hlsUrl: string;
    }[];
    current: number;
  };
  'video:speedOptions': { options: number[]; current: number };
};

export type IFromPlayerApiEventName = keyof FromPlayerEventTypes;

export class FromPlayerEmitter {
  private destroyed = false;
  private nodeConnectedChecker?: PlayerExternalAPIConnectedChecker;

  private element?: HTMLDivElement;
  private playerInstanceId?: string;

  constructor(
    private id?: string,
    private generalEmitter = new EventEmitter<IFromPlayerApiEventName>()
  ) {}

  public setNode = (element: HTMLDivElement) => {
    this.element = element;
  };

  public setNodeConnectedChecker = (
    nodeConnectedChecker: PlayerExternalAPIConnectedChecker
  ) => {
    this.nodeConnectedChecker = nodeConnectedChecker;
  };

  public isInitialized = () => !!this.playerInstanceId;

  public register = (playerInstanceId: string) => {
    this.playerInstanceId = playerInstanceId;
  };

  private isDestroyed = () =>
    (this.nodeConnectedChecker
      ? !this.nodeConnectedChecker?.checkConnected()
      : false) || this.destroyed;

  public destroy = () => {
    this.generalEmitter.removeAllListeners();

    this.destroyed = true;
  };

  /**
   * Create a sort of event emitter for particular event name
   */
  private createEventEmitter = <T extends IFromPlayerApiEventName>(
    eventName: T
  ) => ({
    on: (callback: (payload: FromPlayerEventTypes[T]) => void) => {
      if (this.isDestroyed()) {
        console.warn(
          'Voomly player API is already destroyed. You cannot subscribe to new events'
        );

        return () => {
          console.warn(
            'Voomly player API is already destroyed. You cannot unsubscribe from old events. All subscribers are already removed.'
          );
        };
      }

      this.generalEmitter.on(eventName, callback);
      return () => this.generalEmitter.off(eventName, callback);
    },
    emit: (payload: FromPlayerEventTypes[T]) => {
      if (this.isDestroyed()) {
        return;
      }

      // Pass player instance id and video/funnel id to identify source
      // of event when it was received
      const payloadToPass = {
        ...payload,
        playerInfo: {
          id: this.id,
          instanceId: this.playerInstanceId,
        },
      };

      this.generalEmitter.emit(eventName, payloadToPass);

      if (this.id) {
        this.element?.dispatchEvent(
          new VoomlyPlayerEvent(eventName, payloadToPass)
        );
      }

      // Just send event to the direct subscribers if ids are not defined
      if (!this.id || !this.playerInstanceId) {
        if (this.id) {
          console.warn(
            `Voomly player API is not initialized on the moment of "${eventName}" call, only direct subscribers will be notified.`
          );
        }

        return;
      }

      // Send both to document and window to avoid subscription to only
      // one that doesn't send events
      window?.dispatchEvent(new VoomlyPlayerEvent(eventName, payloadToPass));
      document?.dispatchEvent(new VoomlyPlayerEvent(eventName, payloadToPass));
    },
  });

  /*
   * API start here
   */

  public timelineUnlock = this.createEventEmitter('timeline:unlock');

  public timelineTurnstileSkipped = this.createEventEmitter(
    'timeline:turnstile:skipped'
  );
  public timelineTurnstileCompleted = this.createEventEmitter(
    'timeline:turnstile:completed'
  );

  public timelineButtonClick = this.createEventEmitter('timeline:button:click');
  public timelineButtonEnter = this.createEventEmitter('timeline:button:enter');
  public timelineButtonPreExit = this.createEventEmitter(
    'timeline:button:preExit'
  );
  public timelineButtonExit = this.createEventEmitter('timeline:button:exit');

  public timelineImageClick = this.createEventEmitter('timeline:image:click');
  public timelineImageEnter = this.createEventEmitter('timeline:image:enter');
  public timelineImagePreExit = this.createEventEmitter(
    'timeline:image:preExit'
  );
  public timelineImageExit = this.createEventEmitter('timeline:image:exit');

  public timelineGridClick = this.createEventEmitter('timeline:grid:click');
  public timelineGridEnter = this.createEventEmitter('timeline:grid:enter');
  public timelineGridPreExit = this.createEventEmitter('timeline:grid:preExit');
  public timelineGridExit = this.createEventEmitter('timeline:grid:exit');

  public timelineSurveyClick = this.createEventEmitter('timeline:survey:click');
  public timelineSurveyEnter = this.createEventEmitter('timeline:survey:enter');
  public timelineSurveyPreExit = this.createEventEmitter(
    'timeline:survey:preExit'
  );
  public timelineSurveyExit = this.createEventEmitter('timeline:survey:exit');

  public timelineAnnotationClick = this.createEventEmitter(
    'timeline:annotation:click'
  );
  public timelineImageAnnotationClick = this.createEventEmitter(
    'timeline:imageAnnotation:click'
  );

  public videoIsReady = this.createEventEmitter('video:ready');
  public videoPlayStarted = this.createEventEmitter('video:playStarted');
  public videoPaused = this.createEventEmitter('video:paused');
  public videoSeekStarted = this.createEventEmitter('video:seekStarted');
  public timeUpdate = this.createEventEmitter('video:timeUpdate');
  public videoSeeked = this.createEventEmitter('video:seeked');
  public qualityChanged = this.createEventEmitter('video:qualityChanged');
  public volumeChanged = this.createEventEmitter('video:volumeChanged');
  public speedChanged = this.createEventEmitter('video:speedChanged');
  public videoEnded = this.createEventEmitter('video:ended');

  public qualityOptions = this.createEventEmitter('video:qualityOptions');
  public speedOptions = this.createEventEmitter('video:speedOptions');
}
