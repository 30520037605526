import React from 'react';
import {
  PlayerExternalAPI,
  IPlaybackSettings,
  getLastVisitWatchSeconds,
  VideoItemsContainerProvider,
  PlayerContainerFromEmbedVideoId,
  PlayerMode,
} from '@voomly/player';
import { FunnelsPlayer } from './modules/FunnelsPlayer';
import { createGlobalStyle } from 'styled-components';

const normalizeRatio = (ratio: string) => {
  if (!ratio) {
    return;
  }

  if (!ratio.includes(':')) {
    return parseFloat(ratio);
  }

  const split = ratio.split(':');
  return parseInt(split[0], 10) / parseInt(split[1], 10);
};

const CSSColorVariables = createGlobalStyle`
  .voomly-embed {
    // NOTE: Voomly root is inline-flex element, it makes small gap under the player (about 4px)
    // since it's inline element, we can remove it by setting font-size to 0
    // it's safe because Voomly root has own "font-size: 16px" (libs/player/src/lib/components/VideoLayout/videoLayout.module.sass)
    font-size: 0 !important;
    --azure-500: #008EFF;
  }
`;

export const EmbedApp = ({
  id,
  type,
  ratio,
  playerExternalAPI,
}: {
  type: 'v' | 'f';
  id: string;
  ratio: string;
  playerExternalAPI: PlayerExternalAPI;
}) => {
  const initialPlaybackSettings: IPlaybackSettings = {
    currentTime: 0,
    lastVisitWatchedSeconds: getLastVisitWatchSeconds(id),
  };

  const normalizedRatio = normalizeRatio(ratio) || 1.7777;

  let content: React.ReactNode;
  if (type === 'v') {
    content = (
      <VideoItemsContainerProvider>
        <PlayerContainerFromEmbedVideoId
          ratio={normalizedRatio}
          embedVideoId={id}
          playerMode={PlayerMode.NORMAL}
          playbackSettings={initialPlaybackSettings}
          isRememberDismissEnabled={true}
          playerExternalAPI={playerExternalAPI}
        />
      </VideoItemsContainerProvider>
    );
  }

  if (type === 'f') {
    content = (
      <FunnelsPlayer
        funnelId={id}
        playerMode={PlayerMode.NORMAL}
        ratio={normalizedRatio}
        isRememberDismissEnabled={true}
      />
    );
  }

  if (!content) {
    throw new Error(`Unknown playable type: ${type}`);
  }

  return (
    <>
      <CSSColorVariables />
      {content}
    </>
  );
};
