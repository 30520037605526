import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export interface IVideoState {
  isVideoReady: boolean;
  paused: boolean;
  isPlayPauseSilentMode: boolean;
  isPlayRequested: boolean;
  hasStarted: boolean;
  // metadata might lie. E.g. backend says video duration is 1:59 when it's 2:00
  // before video starts streaming, so it's safer to rely on this flag
  // (changed by 'ended' event)
  isEnded: boolean;
  muted: boolean;
  volume: number;
  speed: number;
  percentsLoaded: number;
  quality: number;

  // Real time where video is
  currentTime: number;
  startedSeekingTime?: number;

  isPlayBlockedBySideEffect: boolean;
}

const initialState: IVideoState = {
  isVideoReady: false,
  hasStarted: false,
  isPlayPauseSilentMode: false,
  isEnded: false,
  isPlayRequested: false,
  currentTime: 0,
  percentsLoaded: 0,
  muted: false,
  paused: true,
  speed: 1,
  volume: 1,
  quality: -1,
  isPlayBlockedBySideEffect: false,
};

type IAction = ActionType<typeof actions>;

export const videoStateReducer = (
  state: IVideoState = initialState,
  action: IAction
): IVideoState => {
  switch (action.type) {
    case getType(actions.changeControls):
      return { ...state, ...action.payload };

    case getType(actions.volumeChanged):
      return {
        ...state,
        volume: action.payload.volume,
        muted: action.payload.muted,
      };

    case getType(actions.qualityChanged):
      return {
        ...state,
        quality: action.payload.quality,
      };

    case getType(actions.videoSeekStarted): {
      return {
        ...state,
        isEnded: false,
        currentTime: action.payload.toTime,
        startedSeekingTime: action.payload.toTime,
      };
    }
    case getType(actions.videoSeeked): {
      if (!state.startedSeekingTime) {
        return state;
      }

      const isSeeked = action.payload.seekType === 'seeked';

      // Set current time to what we plan to seek (startedSeekingTime) when got 'seeked' event
      return {
        ...state,
        isEnded: false,
        currentTime: isSeeked ? state.startedSeekingTime : state.currentTime,
        startedSeekingTime: !isSeeked ? state.startedSeekingTime : undefined,
      };
    }

    case getType(actions.speedChanged):
      return { ...state, speed: action.payload };

    case getType(actions.updateTime):
      return { ...state, currentTime: action.payload.newTime };

    case getType(actions.percentLoadedChanged):
      return { ...state, percentsLoaded: action.payload };

    case getType(actions.videoIsReady):
      return { ...state, isVideoReady: true, isEnded: false };

    case getType(actions.videoPlayStarted):
      return {
        ...state,
        isPlayRequested: true,
        paused: false,
        hasStarted: true,
        isEnded: false,
      };

    case getType(actions.videoPaused):
      return {
        ...state,
        paused: true,
        isPlayRequested: false,
      };

    case getType(actions.playRequested):
      return {
        ...state,
        isPlayRequested: true,
        isEnded: false,
      };

    case getType(actions.playEnded):
      return {
        ...state,
        isEnded: true,
      };

    case getType(actions.requestPlay):
    case getType(actions.requestPause):
      return {
        ...state,
        isPlayPauseSilentMode: !!action.payload.isSilent,
      };
  }

  return state;
};
