import { put, select, takeLatest } from 'redux-saga/effects';
import { PlayerMode } from '../../../components/types/defaultPropTypes';
import { changeLogoVisibility } from '../../videoControls/actions';
import { highlightItemOnScreen } from '../../videoItems/actions';
import { HIGHLIGHT_NOTHING_HIDE_EVERYTHING } from '../../videoItems/constants';
import {
  playEnded,
  requestPause,
  requestPlay,
  requestSeek,
  updateTime,
} from '../actions';
import { isInIFrame } from '@voomly/utils';
import { updateThumbnail, updateVisibility } from '../../thumbnail/actions';
import {
  getPlayerConfig,
  getFile,
  getPlayerMode,
} from '../../sourceConfiguration/selectors';
import { requestNavigateToOtherNode } from '../../sourceConfiguration/actions';
import { getVideoState } from '../selectors';
import { getPlayerExternalAPI } from '../../selectors';

function* handlePlayEnded(_action: ReturnType<typeof playEnded>) {
  const playerConfig = getPlayerConfig(yield select());
  const file = getFile(yield select());
  const playerExternalApi = getPlayerExternalAPI(yield select());
  const playerMode = getPlayerMode(yield select());
  const { muted, volume } = getVideoState(yield select());

  if (file) {
    const duration = file.metadata.duration;

    // Sometime when video ended updateTime is not called, so we do it manually
    yield put(updateTime({ newTime: duration }));
  }

  if (!playerConfig) {
    return;
  }

  playerExternalApi?.fromEmitter.videoEnded.emit();

  const {
    controls: {
      onEnd: { action, redirectUrl },
    },
    playNodeIdOnEnd,
  } = playerConfig;

  // Means need to change the video to the next one;
  // Other actions should take effect when the funnel completely ends (no more "play video when current video ends")
  if (playNodeIdOnEnd || action === 'REQUEST_SWITCH_TO_NEXT_VIDEO') {
    // TODO: merge playNodeIdOnEnd to 'REQUEST_SWITCH_TO_NEXT_VIDEO' action
    // At now REQUEST_SWITCH_TO_NEXT_VIDEO is usede by courses
    yield put(highlightItemOnScreen(HIGHLIGHT_NOTHING_HIDE_EVERYTHING));

    if (playNodeIdOnEnd) {
      yield put(
        requestNavigateToOtherNode({
          nodeId: playNodeIdOnEnd,
          muted,
          volume,
        })
      );
    }
    return;
  }

  switch (action) {
    case 'PAUSE_ON_LAST_FRAME': {
      yield put(requestPause({}));
      yield put(changeLogoVisibility({ isVisible: true }));

      break;
    }

    case 'LOOP':
      yield put(requestSeek({ time: 0, type: 'toTime' }));
      yield put(requestPlay({}));

      break;

    case 'REDIRECT_TO_URL':
      if (playerMode === PlayerMode.NORMAL && !isInIFrame()) {
        const href = redirectUrl;
        if (href) window.location.href = href;
      }
      break;

    case 'RESET_TO_THUMBNAIL':
      yield put(requestPause({}));
      yield put(changeLogoVisibility({ isVisible: true }));

      yield put(updateVisibility(true));
      yield put(updateThumbnail(playerConfig.thumbnail));

      break;
  }
}

export function* playEndedSaga() {
  yield takeLatest(playEnded, handlePlayEnded);
}
