import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NoSoundIcon } from '../../../svg/no-sound.svg';
import {
  requestPlay,
  requestVolumeChange,
} from '../../../store/videoState/actions';
import { IDefaultPropTypes, PlayerMode } from '../../types/defaultPropTypes';
import styles from './clickForSound.module.sass';
import { getIsThumbnailVisible } from '../../../store/thumbnail/selectors';
import { isClickForSoundVisibleSelector } from '../../../store/videoControls/selectors';

const ClickForSound = ({ player, playerMode }: IDefaultPropTypes) => {
  const {
    controls: { enableSoundButtonLocation },
    captions,
  } = player;

  const dispatch = useDispatch();
  const isThumbnailVisible = useSelector(getIsThumbnailVisible);
  const isLoopingThumbnail =
    isThumbnailVisible && player.thumbnail?.type === 'videoLoop';
  const isClickForSoundVisibleSel = useSelector(isClickForSoundVisibleSelector);
  const isClickForSoundVisible =
    isClickForSoundVisibleSel && playerMode !== PlayerMode.MINI_PLAYER;

  const handleClick = () => {
    if (isLoopingThumbnail) {
      dispatch(requestPlay({ time: 0 }));
    }
    dispatch(requestVolumeChange({ mute: false, type: 'mute' }));
  };

  if (!isClickForSoundVisible && !isLoopingThumbnail) return null;

  const pos = (() => {
    if (enableSoundButtonLocation === 'top-left') {
      return { top: 20, left: 20 };
    } else {
      return { top: 20, right: 20 };
    }
  })();

  return (
    <div className={styles.root} onClick={handleClick} style={pos}>
      <div className={styles.button}>
        <NoSoundIcon />
        <span>{captions.enableSoundText}</span>
      </div>
    </div>
  );
};
export default ClickForSound;
