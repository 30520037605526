import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import to from 'await-to-js';
import { pickAlign, useFontFamily } from '@voomly/ui/player-deps';
import { NON_EXISTING_SUBTITLES_ID } from '@voomly/utils';
import { getCurrentTime } from '../../store/videoState/selectors';
import { getAreControlsVisible } from '../../store/videoControls/selectors';
import { IDefaultPropTypes } from '../../components/types/defaultPropTypes';
import { usePlayerSkin } from '../Controls/skins/PlayerSkinContext';
import {
  currentSubtitlesNameSelector,
  subtitlesIsVisibleSelector,
} from '../../store/subtitles/selectors';
import {
  getSubtitlesPublicRequest,
  IFsFileItemSubtitles,
} from '../../api/subtitles';

const StyledSubtitlesHolder = styled.div`
  position: absolute;
  z-index: 2; // $zSubtitles
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: transform 0.2s ease-out;
`;

const StyledSubtitles = styled.div`
  font-size: 0; // To remove space between inline-block elements
`;

const StyledSubtitlesLine = styled.span`
  display: inline-block;
  white-space: pre-wrap;
`;

const SubtitlesComponent = ({
  buttonsScale,
  player,
  file,
}: IDefaultPropTypes) => {
  const {
    appearance: {
      subtitles: {
        fontSize,
        fontStyle,
        textAlign,
        color,
        fontFamily,
        backgroundColor,
      },
    },
    subtitles,
  } = player;
  useFontFamily(fontFamily);
  const skin = usePlayerSkin();
  const currentTime = useSelector(getCurrentTime);
  const areControlsVisible = useSelector(getAreControlsVisible);
  const isSubtitlesVisible = useSelector(subtitlesIsVisibleSelector);
  const currentSubtitlesName = useSelector(currentSubtitlesNameSelector);
  const [subtitlesContent, setSubtitlesContent] = useState<
    IFsFileItemSubtitles['content'] | undefined
  >(undefined);

  const enabledSubtitles = useMemo(() => {
    return subtitles?.items.filter((s) => s.isEnabled) || [];
  }, [subtitles?.items]);

  useEffect(() => {
    (async () => {
      setSubtitlesContent(undefined);
      if (currentSubtitlesName && isSubtitlesVisible) {
        const currentSubtitlesItem = enabledSubtitles.find(
          (s) => s.name === currentSubtitlesName
        );

        // On the funnel video player we are able to add subtitles with non-existing subtitlesId
        // example - `${shortid.generate()}|${NON_EXISTING_SUBTITLES_ID}`
        if (
          !currentSubtitlesItem ||
          currentSubtitlesItem.subtitlesId.includes(NON_EXISTING_SUBTITLES_ID)
        ) {
          return;
        }

        const [error, response] = await to(
          getSubtitlesPublicRequest(file.id, currentSubtitlesItem.subtitlesId)
        );

        if (error) {
          console.log('Subtitles error: ', error.message);
          return;
        }

        if (response) {
          setSubtitlesContent(response.data.content);
        }
      }
    })();
  }, [currentSubtitlesName, file.id, isSubtitlesVisible, enabledSubtitles]);

  if (!isSubtitlesVisible) return null;

  const subtitlesPositionWithoutControls = 10;
  const subtitlesPositionWithControls =
    subtitlesPositionWithoutControls + skin.approximateBarHeight;

  return (
    <StyledSubtitlesHolder
      style={{
        textAlign: pickAlign([textAlign]),
        padding: `0 ${15 * buttonsScale}px`,
        transform: `translateY(-${
          areControlsVisible
            ? subtitlesPositionWithControls
            : subtitlesPositionWithoutControls
        }px)`,
      }}
    >
      {subtitlesContent?.items.map((el) => {
        if (currentTime >= el.startTime && currentTime <= el.endTime) {
          return el.content.split('\n').map((text, index) => (
            <StyledSubtitles key={`${el.startTime}-${el.endTime}-${index}`}>
              <StyledSubtitlesLine
                style={{
                  backgroundColor,
                  color,
                  fontFamily,
                  fontSize: `${fontSize * buttonsScale}px`,
                  lineHeight: `${fontSize * 1.12 * buttonsScale}px`,
                  fontWeight: fontStyle.includes('bold') ? 'bold' : undefined,
                  fontStyle: fontStyle.includes('italic')
                    ? 'italic'
                    : undefined,
                  textDecoration: `${
                    fontStyle.includes('underline') ? 'underline' : ''
                  } ${
                    fontStyle.includes('line-through') ? 'line-through' : ''
                  }`,
                  padding: `${2 * buttonsScale}px ${5 * buttonsScale}px`,
                }}
              >
                {text}
              </StyledSubtitlesLine>
            </StyledSubtitles>
          ));
        }
        return null;
      })}
    </StyledSubtitlesHolder>
  );
};

export const Subtitles = memo(SubtitlesComponent);
