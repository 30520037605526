import { put, select, takeLatest } from 'redux-saga/effects';
import { hasActionOnClick } from '../../types/guards';
import { appendBackButtonReference } from '../miscScreenObjects/actions';
import { requestNavigateToOtherNode } from '../sourceConfiguration/actions';
import {
  getCurrentFunnelNodeId,
  getIsVideoChanging,
} from '../sourceConfiguration/selectors';
import { requestPause, requestPlay } from '../videoState/actions';
import { getCurrentTime, getVideoState } from '../videoState/selectors';
import {
  dismissVideoItemOnScreen,
  highlightItemOnScreen,
  itemClicked,
} from './actions';
import { getPlayerExternalAPI } from '../selectors';

function* handleItemClick(event: ReturnType<typeof itemClicked>) {
  const { item } = event.payload;
  const { muted, volume } = getVideoState(yield select());
  const playerExternalApi = getPlayerExternalAPI(yield select());

  if (getIsVideoChanging(yield select())) {
    console.warn("Can't handle item click while video is changing");

    return;
  }

  switch (item.type) {
    case 'button': {
      playerExternalApi?.fromEmitter.timelineButtonClick.emit(item);
      break;
    }
    case 'image': {
      playerExternalApi?.fromEmitter.timelineImageClick.emit(item);
      break;
    }
    case 'annotation': {
      playerExternalApi?.fromEmitter.timelineAnnotationClick.emit(item);
      break;
    }
    case 'imageAnnotation': {
      playerExternalApi?.fromEmitter.timelineImageAnnotationClick.emit(item);
      break;
    }
    case 'grid': {
      playerExternalApi?.fromEmitter.timelineGridClick.emit(item);
      break;
    }
    case 'survey': {
      playerExternalApi?.fromEmitter.timelineSurveyClick.emit(item);
      break;
    }
  }

  if (hasActionOnClick(item)) {
    const { actionOnClick } = event.payload.item;

    if (actionOnClick.type === 'openUrl') {
      // https://stackoverflow.com/questions/40593632/use-window-open-but-block-use-of-window-opener/40593743#40593743
      const a = document.createElement('a');
      a.href = actionOnClick.url;
      a.target = actionOnClick.openInNewTab ? '_blank' : '_self';
      // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
      a.rel = 'nofollow noopener noreferrer';
      a.click();
    }

    if (actionOnClick.type === 'playNode' && actionOnClick.nodeId) {
      const currentNodeId = getCurrentFunnelNodeId(yield select());
      const currentTime = getCurrentTime(yield select());

      // That's the only way how "Back" button may appear; other onNavigateToOtherNodeRequest
      // call happens when video ends, and there should be no back button for that
      if (currentNodeId) {
        yield put(
          appendBackButtonReference({
            nodeId: currentNodeId,
            timestamp: currentTime,
          })
        );
      }

      yield put(highlightItemOnScreen(item.id));
      yield put(requestPause({ isSilent: true }));
      yield put(
        requestNavigateToOtherNode({
          nodeId: actionOnClick.nodeId,
          muted,
          volume,
        })
      );
    }

    if (
      (item.type === 'grid' || item.type === 'survey') &&
      actionOnClick.type === 'unpauseVideo'
    ) {
      // item.id is a composite of gridId + buttonId
      const [gridId] = item.id.split('|');

      yield put(
        dismissVideoItemOnScreen({
          id: gridId,
          remember: false,
        })
      );
    }

    if (actionOnClick.type === 'dismiss') {
      yield put(
        dismissVideoItemOnScreen({
          id: item.id,
          remember: false,
        })
      );
    }

    if (actionOnClick.type === 'unpauseVideo') {
      yield put(requestPlay({}));
    }
  }
}

export function* clickItemsSaga() {
  yield takeLatest(itemClicked, handleItemClick);
}
