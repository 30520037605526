import * as React from 'react';
import { useCallback, useContext } from 'react';
import {
  BaseTurnstileItem,
  IBaseTurnstileFormValues,
  ITurnstileItemProps,
} from './BaseTurnstile';
import { useSelector } from 'react-redux';
import { IBody, subscribe } from '../../../api/turnstile';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';
import { getIsCustomTurnstileIntegrationEnabled } from '../../../store/customIntegrations/selectors';
import { PlayerExternalAPIContext } from '../PlayerExternalAPIContext';

export const PlayerTurnstileItem = (props: ITurnstileItemProps) => {
  const { item, onDismiss } = props;

  const file = useSelector(
    (state: IPlayerRootReducerShape) => state.sourceConfiguration.current?.file
  );
  const funnelId = useSelector(
    (state: IPlayerRootReducerShape) =>
      state.sourceConfiguration?.current?.funnelId
  );
  const playerExternalApi = useContext(PlayerExternalAPIContext);
  const isCustomTurnstileIntegrationEnabled = useSelector(
    getIsCustomTurnstileIntegrationEnabled
  );

  const handleSkip = useCallback(() => {
    if (isCustomTurnstileIntegrationEnabled) {
      playerExternalApi?.fromEmitter?.timelineTurnstileSkipped.emit();
    }
    onDismiss(item);
  }, [item, onDismiss, playerExternalApi, isCustomTurnstileIntegrationEnabled]);

  const handleSubmit = useCallback(
    async (values: IBaseTurnstileFormValues) => {
      const valuesToSend: IBody = item.isNameRequired
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          }
        : { email: values.email };

      // Custom integration for ClickFunnels
      if (isCustomTurnstileIntegrationEnabled) {
        playerExternalApi?.fromEmitter?.timelineTurnstileCompleted.emit(
          valuesToSend
        );
        onDismiss(item, true);
        return true;
      }

      if (!funnelId && !file) {
        alert(item.failedToSubmitText);
        return false;
      }

      let result = {
        ok: false,
      };

      if (funnelId) {
        result = await subscribe(item.id, valuesToSend, { funnelId });
      } else if (file) {
        result = await subscribe(item.id, valuesToSend, {
          videoId: file.id,
          type: 'voomly',
        });
      }

      if (!result.ok) {
        alert(item.failedToSubmitText);
        return false;
      }

      onDismiss(item, true);
      return true;
    },
    [
      file,
      funnelId,
      item,
      onDismiss,
      playerExternalApi,
      isCustomTurnstileIntegrationEnabled,
    ]
  );

  return (
    <BaseTurnstileItem
      {...props}
      onSubmit={handleSubmit}
      onSkip={handleSkip}
      isPreviewMode={false}
      titleText={item.titleText}
      subTitleText={item.subTitleText}
      formFirstNameText={item.formFirstNameText || 'First name'}
      formLastNameText={item.formLastNameText || 'Last name'}
      formEmailText={item.formEmailText || 'Email address'}
      formEmailTextRaw={item.formEmailText || 'Email address'}
    />
  );
};
