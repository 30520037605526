import {
  IToPlayerApiEventName,
  ToPlayerDispatcher,
  ToPlayerEventTypes,
} from '../ToPlayerDispatcher';
import { toExternalEventName } from './../../VoomlyPlayerEvent';
import { VoomlyToPlayerMessage } from '../../VoomlyPlayerMessage';

export class ToIFrameDispatcher extends ToPlayerDispatcher {
  constructor() {
    super();

    // Listen to all API we defined in parent class
    Object.getOwnPropertyNames(this).forEach((item) => {
      if (this[item]?.eventName && this[item]?.emit) {
        this.listenToEvent(this[item]);
      }
    });
  }

  /**
   * Add event listener for every player event that
   * comes from the parent window (outside of iframe)
   */
  private listenToEvent = <T extends IToPlayerApiEventName>(emitterDescriptor: {
    eventName: T;
    emit: (payload: ToPlayerEventTypes[T]) => void;
  }) => {
    window.addEventListener(
      'message',
      ({ data }: { data: VoomlyToPlayerMessage<T> }) => {
        if (
          toExternalEventName(emitterDescriptor.eventName) !== data.eventName
        ) {
          return;
        }

        emitterDescriptor.emit(data.payload);
      }
    );
  };
}
