import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ReplayIcon } from '../../../svg/replay.svg';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { requestPlay as requestPlayAction } from '../../../store/videoState/actions';
import { shouldShowReWatchButton } from './selector';
import styles from './styles.module.sass';
import { getIsVideoFinished } from '../../../store/videoState/selectors';

const RewatchButton = ({ player }: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const isVideoEnded = useSelector(getIsVideoFinished);
  const handlePlay = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      dispatch(requestPlayAction({}));
    },
    [dispatch]
  );

  if (!shouldShowReWatchButton(player, isVideoEnded)) {
    return null;
  }

  return (
    <div className={styles.rewatchButtonWrapper}>
      <button className={styles.rewatchButton} onClick={handlePlay}>
        <span>
          <ReplayIcon className={styles.replayIcon} />
          {player.captions.rewatchText}
        </span>
      </button>
    </div>
  );
};

export default memo(RewatchButton);
