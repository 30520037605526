import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Button from '../Button/Button';
import { getStyles } from '../skins/getStyles';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { requestPause, requestPlay } from '../../../store/videoState/actions';
import styles from './styles.module.sass';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import { getIsVideoPaused } from '../../../store/videoState/selectors';

const TogglePlayButtonComponent = ({ player }: IDefaultPropTypes) => {
  const { captions } = player;
  const dispatch = useDispatch();
  const paused = useSelector(getIsVideoPaused);
  const skin = usePlayerSkin();

  const handleClick = useCallback(() => {
    if (paused) {
      dispatch(requestPlay({}));
    } else {
      dispatch(requestPause({}));
    }
  }, [paused, dispatch]);

  if (!player.controls.showPlayButton) return null;

  const PlayIcon = skin.icons.togglePlay;
  const PauseIcon = skin.icons.togglePause;

  return (
    <Button
      className={cx(styles.root, skin.classes.togglePlayButton__root, {
        [skin.classes.togglePlayButton__play]: paused,
        [skin.classes.togglePlayButton__pause]: !paused,
      })}
      style={getStyles('togglePlayButton__root', skin, player)}
      config={player}
      onClick={handleClick}
      icon={paused ? <PlayIcon /> : <PauseIcon />}
      title={paused ? captions.playText : captions.pauseText}
    />
  );
};

export default memo(TogglePlayButtonComponent);
