import React, { memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Ratio, Loader } from '@voomly/ui/player-deps';
import { IDefaultPropTypes, PlayerMode } from '../types/defaultPropTypes';
import {
  getFile,
  getPlayerConfig,
  getPlayerMode,
} from '../../store/sourceConfiguration/selectors';
import {
  dismissVideoItemOnScreen,
  itemClicked,
} from '../../store/videoItems/actions';
import { usePlayerKeybindings } from '../../hooks/usePlayerKeybindings';
import { MouseChannelProvider } from '../../contexts/MouseMoveContext';
import FullScreenObserver from '../WithFullScreen/WithFullScreen';
import Controls from '../Controls/Controls';
import { ITimelineItemClickDescriptor } from '../../types/types';
import VideoWithControls from './VideoWithControls';
import { IPlaybackSettings } from './PlayerContainer';
import { VideoLayout } from '../VideoLayout/VideoLayout';
import { BorderSkinLayout } from '../BorderSkin/BorderSkinLayout';
import { PlayerSkinContextProvider } from '../Controls/skins/PlayerSkinContext';

interface IConnectedBorderSkinProps {
  playbackSettings: IPlaybackSettings;
  ratio?: number;
  // This is some extra element that depends on player size, for example,
  // a close button that should be in the top right corner of the video
  extraContent: React.ReactNode;
}

const PlayerComponent: React.FC<IConnectedBorderSkinProps> = ({
  playbackSettings,
  extraContent,
  ratio,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const playerMode = useSelector(getPlayerMode);
  const file = useSelector(getFile);
  const player = useSelector(getPlayerConfig);
  const listeners = usePlayerKeybindings(containerRef);

  const handleItemClick = useCallback(
    (item: ITimelineItemClickDescriptor) => {
      dispatch(itemClicked({ item }));
    },
    [dispatch]
  );

  const handleItemDismiss = useCallback(
    (item: ITimelineItemClickDescriptor, remember?: boolean) => {
      dispatch(
        dismissVideoItemOnScreen({ id: item.id, remember: Boolean(remember) })
      );
    },
    [dispatch]
  );

  if (!player || !playerMode || !file) {
    return (
      <Ratio ratio={ratio || 1.777777}>
        <Loader
          color={
            player && player.appearance.bgColorEnabled
              ? player.appearance.bgColor
              : undefined
          }
        />
      </Ratio>
    );
  }

  // NOTE: while processing video the size will be zero
  const videoRatio = file.metadata.video.width / file.metadata.video.height;
  const initialOrVideoRatio = ratio || videoRatio;

  if (player.appearance.borderSkinEnabled && ratio) {
    console.log(
      'NOTE: Border skin is enabled and fixed ratio is set, this is not supported. Fixed ratio will be ignored.'
    );
  }

  const controlsProps: IDefaultPropTypes = {
    playerMode,
    file,
    player,
    buttonsScale: 1,
    allControlsAreAlwaysVisible: Boolean(
      playbackSettings.allControlsAreAlwaysVisible
    ),
  };

  return (
    <MouseChannelProvider playerMode={playerMode}>
      <PlayerSkinContextProvider
        playerMode={playerMode}
        loaderRatio={initialOrVideoRatio}
        player={player}
      >
        <BorderSkinLayout
          innerRef={containerRef}
          videoRatio={initialOrVideoRatio}
          player={player}
          extraContent={extraContent}
          onMouseDown={listeners.onMouseDown}
          onTouchStart={listeners.onTouchStart}
        >
          <VideoLayout videoRatio={videoRatio}>
            <FullScreenObserver>
              <VideoWithControls
                onTimelineItemClick={handleItemClick}
                onTimelineItemDismiss={handleItemDismiss}
                player={player}
                file={file}
                playerMode={playerMode}
                allControlsAreAlwaysVisible={
                  controlsProps.allControlsAreAlwaysVisible
                }
              />
              {playerMode !== PlayerMode.THUMBNAIL_EDITOR && (
                <Controls {...controlsProps} />
              )}
            </FullScreenObserver>
          </VideoLayout>
          {playerMode !== PlayerMode.THUMBNAIL_EDITOR && (
            <Controls isOutSide={true} {...controlsProps} />
          )}
        </BorderSkinLayout>
      </PlayerSkinContextProvider>
    </MouseChannelProvider>
  );
};

export const Player = memo(PlayerComponent);
