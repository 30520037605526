import { useState, useCallback, useLayoutEffect, useRef, useMemo } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import { ResizeObserverCallback } from '@juggle/resize-observer/lib/ResizeObserverCallback';
import uuid from 'uuid';
import { usePrevious } from 'react-use';

export type DimensionObject = {
  width: number;
  height: number;
};

export type UseResizableRatioHook = [
  (node: HTMLElement | null) => void,
  DimensionObject,
  boolean,
  HTMLElement | null
];

const handleParseFloat = (val: string) => parseFloat(val) || 0;

const addResizeHelper = (node: Element, id: string, height?: number) => {
  removeResizeHelper(node, id);

  const helper = document.createElement('div');
  helper.className = id;
  helper.style.opacity = '0';
  helper.style.height = height ? `${height}px` : `30px`;
  node.appendChild(helper);
};

const removeResizeHelper = (node: Element, id: string) => {
  const helpers = node.getElementsByClassName(id);
  for (let i = 0; i < helpers.length; i++) {
    const helper = helpers[i];
    node.removeChild(helper);
  }
};

const getElementInnerDimensions = (element: HTMLElement) => {
  const cs = getComputedStyle(element);
  const paddingX =
    handleParseFloat(cs.paddingLeft) + handleParseFloat(cs.paddingRight);
  const paddingY =
    handleParseFloat(cs.paddingTop) + handleParseFloat(cs.paddingBottom);

  const borderX =
    handleParseFloat(cs.borderLeftWidth) +
    handleParseFloat(cs.borderRightWidth);
  const borderY =
    handleParseFloat(cs.borderTopWidth) +
    handleParseFloat(cs.borderBottomWidth);

  return {
    width: element.offsetWidth - paddingX - borderX,
    height: element.offsetHeight - paddingY - borderY,
  };
};

const getRatioDimensions = (ratio: number, width: number, height: number) => {
  const videoWidth = Math.min(width, height * ratio);
  const videoHeight = Math.min(height, width / ratio);

  return {
    width: videoWidth,
    height: videoHeight,
  };
};

function useResizableRatio({
  name,
  ratio,
  isFullScreen,
}: {
  name?: string;
  ratio: number;
  isFullScreen?: boolean | undefined;
}): UseResizableRatioHook {
  const log = (...value: any[]) => {
    return false;
    // console.log(`Name: ${name}`, ...value);
  };
  const prevRatio = usePrevious(ratio);
  const resizeHelperIdRef = useRef(`resize-helper-${uuid.v4()}`);
  const [node, setNode] = useState<HTMLElement | null>(null);
  const isReadyRef = useRef<boolean | undefined>(undefined);
  const [isReady, setIsReady] = useState<boolean | undefined>(undefined);
  const [{ width, height }, setDimensions] = useState<DimensionObject>({
    width: 0,
    height: 0,
  });
  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  const handleResize = useCallback(
    (element: HTMLElement) => {
      const { width: parentWidth, height: parentHeight } =
        getElementInnerDimensions(element);
      const { width: videoWidth, height: videoHeight } = getRatioDimensions(
        ratio,
        parentWidth,
        parentHeight
      );

      log('Dimensions:', {
        parentWidth,
        parentHeight,
        videoWidth,
        videoHeight,
      });

      setIsReady(true);
      isReadyRef.current = true;

      removeResizeHelper(element, resizeHelperIdRef.current);

      setDimensions({
        width: videoWidth,
        height: videoHeight,
      });
    },
    [ratio]
  );

  const onParentResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      window.requestAnimationFrame(() => {
        const { inlineSize: parentWidth, blockSize: parentHeight } =
          entries[0].contentBoxSize[0];

        log('Start - onParentResize', parentWidth, parentHeight);

        const parentElement = entries[0].target as HTMLElement;

        if (!ratio) {
          log({ ratio });
          throw new Error("Can't set Layout dimensions");
        }

        if (isFullScreen !== undefined && isFullScreen) {
          log('onParentResize fullScreen');
          setDimensions({
            width: Math.min(parentWidth, parentHeight * ratio),
            height: Math.min(parentHeight, parentWidth / ratio),
          });
        } else {
          log('onParentResize with cb');
          // const realVideoWidth = parentWidth;
          // const realVideoHeight = parentWidth / ratio;

          // 1. Stretch video by available width and full height by ratio
          // v1. We have stretched the video itself, and it makes the video bigger
          // than the available height for milliseconds and after this lag the final fix is called
          // to fit the video to the available height.
          // v2. We stretched the parent element by invisible helper <div/>
          // in this case we will not see the lag when the video is bigger than the available height
          // Q: Why do we need it?
          // A: We can put the video inside a <div/> and then stretch it by available width and full height by ratio
          // and if a <div/> will change its size (no matter by width or height!) the video will be stretched too by the same ratio.
          addResizeHelper(parentElement, resizeHelperIdRef.current);

          // 2. And fix video if it's overflow parent container
          handleResize(parentElement);
        }
      });
    },
    [ratio, isFullScreen, handleResize]
  );

  useLayoutEffect(() => {
    const parentElement = node?.parentElement;
    const triggerResize = () => {
      window.requestAnimationFrame(() => {
        log('Trigger resize');
        parentElement &&
          addResizeHelper(parentElement, resizeHelperIdRef.current, 1);
      });
    };
    window.addEventListener('resize', triggerResize);

    return () => {
      window.removeEventListener('resize', triggerResize);
    };
  }, [node?.parentElement]);

  useLayoutEffect(() => {
    const parentElement = node?.parentElement;
    if (parentElement && prevRatio !== ratio) {
      log('ratio changed - make extra call handleResize()');
      handleResize(parentElement);
    }
  }, [handleResize, node?.parentElement, prevRatio, ratio]);

  // When the node is set, we need to set the initial dimensions to avoid jumping
  useLayoutEffect(() => {
    if (!node?.parentElement) return;

    const { width: parentWidth } = getElementInnerDimensions(
      node.parentElement
    );

    log('Initial dimensions:', {
      width: parentWidth,
      height: parentWidth / ratio,
    });

    // NOTE: parentWidth and videoHeight is correct here
    setDimensions({ width: parentWidth, height: parentWidth / ratio });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  useLayoutEffect(() => {
    if (!node) return;

    // useResizableRatio should depend only on parent container
    // useResizableRatio used in two cases:
    // if border enabled - used two 'useResizableRatio', 1 in VideoLayout.tsx, 2 in BorderSkin.tsx
    // if border disabled - used one 'useResizableRatio', 1 in VideoLayout.tsx, 2 in BorderSkin.tsx is ignored
    //
    // BorderSkin.tsx use 'useResizableRatio' only for one reason to properly adjust container aspect ratio
    // VideoLayout.tsx use 'useResizableRatio' and provide video dimensions to the store
    const parentElement = node.parentElement;

    if (!parentElement) throw new Error('Parent element not found');

    const resizeObserver = new ResizeObserver(onParentResize);

    if (isFullScreen !== undefined && isFullScreen) {
      const fullScreenDiv =
        parentElement?.querySelector('.fullscreen') ||
        parentElement?.querySelector('.fullscreen-simulated');

      if (!fullScreenDiv) {
        console.error(
          'Looks like fullscreen is enabled, but fullscreen DIV not found.'
        );
      } else {
        resizeObserver.observe(fullScreenDiv);
      }
    } else {
      resizeObserver.observe(parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [node, onParentResize, isFullScreen]);

  return useMemo(
    () => [ref, { width, height }, Boolean(isReady), node],
    [ref, width, height, isReady, node]
  );
}

export { useResizableRatio };
